<template>
  <v-app>
    <v-container>
      <v-slide-y-transition>
        <v-skeleton-loader
          v-if="isLoading"
          type="card"
          class="mx-auto my-10"
          max-width="600"
        ></v-skeleton-loader>
      </v-slide-y-transition>

      <v-slide-y-transition>
        <v-card v-show="!isLoading" class="mx-auto my-10" max-width="600" elevation="12">
          <v-img height="250" src="../assets/RB-logo.png" alt="Company Logo"></v-img>
          <v-card-title>Uppdatera Profil</v-card-title>
          
          <v-card-text>
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="profile.enhetsNamn"
                :rules="[rules.optional]"
                label="Enhets Namn"
                outlined
                dense
                aria-label="Enhets Namn"
                @input="debounceInput('enhetsNamn', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.adress"
                :rules="[rules.optional]"
                label="Adress"
                outlined
                dense
                aria-label="Adress"
                @input="debounceInput('adress', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.postnr"
                :rules="[rules.optional, rules.numeric]"
                label="Postnummer"
                outlined
                dense
                aria-label="Postnummer"
                @input="debounceInput('postnr', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.ort"
                :rules="[rules.optional]"
                label="Ort"
                outlined
                dense
                aria-label="Ort"
                @input="debounceInput('ort', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.firstName"
                :rules="[rules.optional]"
                label="Förnamn"
                outlined
                dense
                aria-label="Förnamn"
                @input="debounceInput('firstName', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.lastName"
                :rules="[rules.optional]"
                label="Efternamn"
                outlined
                dense
                aria-label="Efternamn"
                @input="debounceInput('lastName', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.email"
                :rules="[rules.optional, rules.email]"
                label="Email"
                outlined
                dense
                aria-label="Email"
                @input="debounceInput('email', $event)"
              ></v-text-field>
              <v-text-field
                v-model="profile.telefon"
                :rules="[rules.optional, rules.numeric]"
                label="Telefon"
                outlined
                dense
                aria-label="Telefon"
                @input="debounceInput('telefon', $event)"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-btn 
              color="#383e42" 
              class="white--text" 
              @click="showConfirmDialog"
              :disabled="!isFormChanged || !valid"
            >
              Uppdatera profil
            </v-btn>
            <v-btn text @click="resetForm">Återställ</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-slide-y-transition>

      <v-dialog v-model="confirmDialog" max-width="300">
        <v-card>
          <v-card-title>Bekräfta uppdatering</v-card-title>
          <v-card-text>Är du säker på att du vill uppdatera din profil?</v-card-text>
          <v-card-actions>
            <v-btn text @click="confirmDialog = false">Avbryt</v-btn>
            <v-btn color="primary" @click="confirmUpdate">Bekräfta</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="3000"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbar.show = false"
          >
            Stäng
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      valid: false,
      isLoading: false,
      profile: {
        enhetsNamn: '',
        adress: '',
        postnr: '',
        ort: '',
        firstName: '',
        lastName: '',
        email: '',
        telefon: ''
      },
      originalProfile: {},
      rules: {
        optional: () => true,
        email: value => !value || /.+@.+\..+/.test(value) || 'E-postadressen måste vara giltig.',
        numeric: value => !value || /^\d+$/.test(value) || 'Måste vara numeriskt.'
      },
      isProfilePage: false,
      confirmDialog: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    };
  },
  computed: {
    ...mapGetters(['getToken', 'currentUser']),
    isFormChanged() {
      return Object.keys(this.profile).some(key => this.profile[key] !== this.originalProfile[key]);
    }
  },
  methods: {
    async fetchUserProfile() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('fetchUserProfile');
        if (response && response.data) {
          this.profile = response.data;
          this.originalProfile = { ...this.profile };
        } else {
          throw new Error('Invalid response structure from store');
        }
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        this.showSnackbar('Failed to load user profile', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    showConfirmDialog() {
      this.confirmDialog = true;
    },
    async confirmUpdate() {
      this.confirmDialog = false;
      await this.updateProfile();
    },
    async updateProfile() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          const updatedFields = Object.keys(this.profile).reduce((acc, key) => {
            if (this.profile[key] !== this.originalProfile[key]) {
              acc[key] = this.profile[key];
            }
            return acc;
          }, {});

          await this.$store.dispatch('updateUserProfile', updatedFields);
          this.showSnackbar('Profilen har uppdaterats', 'success');
          this.originalProfile = { ...this.profile };
        } catch (error) {
          console.error('Failed to update profile:', error);
          const errorMessage = error.response?.data?.message || 'Failed to update profile';
          this.showSnackbar(errorMessage, 'error');
        } finally {
          this.isLoading = false;
        }
      }
    },
    resetForm() {
      this.profile = { ...this.originalProfile };
      this.$refs.form.resetValidation();
    },
    showSnackbar(text, color = 'success') {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    debounceInput: _.debounce(function(field, value) {
      this.profile[field] = value;
    }, 300),
    goToProfile() {
      this.$router.push('/profile-update');
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.fetchUserProfile();
    this.isProfilePage = this.$route.path === '/profile-update';
  }
};
</script>

<style scoped>
.v-btn {
  text-transform: none;
}

.v-card {
  max-width: 600px;
  margin: 12px auto;
}
.white--text {
  color: white;
  text-decoration: none;
}
</style>